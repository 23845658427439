import React from "react"
import { format } from "date-fns"
import { LinkBox, LinkOverlay, Box, Heading, AspectRatio, Image } from "@chakra-ui/react"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"
import { useImage } from "@app/hooks/useImage"
import { useMediaMobile } from "@app/hooks/useMedia"

const ArticleCard = ({ article, featured = false }) => {
  const { urlResolver } = useRoutes()
  const { getResponsiveImage } = useImage()
  const isMobile = useMediaMobile()

  const item = {
    ...article,
    link: urlResolver(article),
    date: article.attributes?.publishedAt ? format(new Date(article.attributes.publishedAt), "dd.MM.yy") : "",
    image: getResponsiveImage(article?.previewImage || article?.image),
  }

  return (
    <LinkBox as="article" overflow="hidden" pb={4}>
      {item?.image?.desktop && !isMobile && (
        <Box w="100%" mb="4">
          <AspectRatio w="100%" ratio={featured ? 3 : 1}>
            <Image {...item?.image?.desktop} w="full" objectFit="cover" objectPosition="center" />
          </AspectRatio>
        </Box>
      )}
      {item?.image?.mobile && isMobile && (
        <Box w="100%" mb="4">
          <AspectRatio w="100%" ratio={1}>
            <Image {...item?.image?.mobile} w="full" objectFit="cover" objectPosition="center" />
          </AspectRatio>
        </Box>
      )}

      <Box align="left">
        <Heading as="p" size="h5" mb="2">
          {item?.subtitle}
        </Heading>
        <Heading as="h3" size="h3" mb="4">
          <LinkOverlay as={Link} to={item?.link.url} title={item?.link.title}>
            {item?.title}
          </LinkOverlay>
        </Heading>
        <Heading as="p" size="h5">
          {item?.date}
        </Heading>
      </Box>
    </LinkBox>
  )
}

export default React.memo(ArticleCard)
