import React from "react"
import { format } from "date-fns"
import { LinkBox, LinkOverlay, Box, Heading, AspectRatio, Container, Divider, Image } from "@chakra-ui/react"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"
import { useImage } from "@app/hooks/useImage"
import { useMediaMobile } from "@app/hooks/useMedia"

const ArticleFeaturedCard = ({ article, desktopDivider = false }) => {
  const { urlResolver } = useRoutes()
  const { getResponsiveImage } = useImage()
  const isMobile = useMediaMobile()
  const item = {
    ...article,
    link: urlResolver(article),
    date: article.attributes?.publishedAt ? format(new Date(article.attributes.publishedAt), "dd.MM.yy") : "",
    image: getResponsiveImage(article?.previewImage || article?.image),
  }

  return (
    <>
      <LinkBox as="article" overflow="hidden" position="relative" mb={["0", desktopDivider ? "0" : "20"]}>
        {item?.image?.desktop && !isMobile && (
          <Box height="400px" w="100%">
            <AspectRatio w="100%" h="100%">
              <Image {...item?.image?.desktop} objectFit="cover" objectPosition="center" />
            </AspectRatio>
          </Box>
        )}
        {item?.image?.mobile && isMobile && (
          <Box w="100%">
            <AspectRatio w="100%" ratio={1}>
              <Image {...item?.image?.mobile} objectFit="cover" objectPosition="center" />
            </AspectRatio>
          </Box>
        )}
        <Container position={["static", "absolute"]} inset={0} height="100%" maxW="container.lg" mt={["8", "0"]}>
          <Box
            align="left"
            position={["static", "absolute"]}
            display={["block", "flex"]}
            flexDirection="column"
            justifyContent="space-between"
            right="10"
            top="6"
            bottom="6"
            bg="white"
            p={["0", "10"]}
            maxW={["full", 352]}
          >
            <Box>
              <Heading as="p" size="h5" mb={["2", "6"]}>
                {item?.subtitle}
              </Heading>
              <Heading as="h3" size="h2" mb="4">
                <LinkOverlay as={Link} to={item?.link.url} title={item?.link.title}>
                  {item?.title}
                </LinkOverlay>
              </Heading>
            </Box>
            <Heading as="p" size="h5">
              {item?.date}
            </Heading>
          </Box>
          {isMobile && <Divider />}
        </Container>
      </LinkBox>
      {desktopDivider && !isMobile && <Divider my={16} />}
    </>
  )
}

export default React.memo(ArticleFeaturedCard)
