import React from "react"
import { graphql, PageProps } from "gatsby"
import Page from "@app/components/Article/ArticleList"

export type Props = PageProps<GatsbyTypes.PageArticlesQuery, GatsbyTypes.PageArticlesQueryVariables>

export const query = graphql`
  query PageArticles {
    page: sanityPageArticles {
      title
      url
      categories {
        title
        url
        description
        handle {
          current
        }
      }
      featured {
        handle {
          current
        }
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
    articles: allSanityArticle(
      filter: { attributes: { publishedAt: { ne: null } } }
      sort: { fields: attributes___publishedAt, order: DESC }
    ) {
      edges {
        node {
          ...SanityArticleFragment
        }
      }
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
