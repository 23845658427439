import React from "react"
import { Container, SimpleGrid, Box, Heading, Text, Divider, Button, Flex } from "@chakra-ui/react"
import { Link } from "gatsby"

import type { Props } from "@app/pages/journal"
import type { PageProps } from "@root/types/global"
import { useShopify } from "@app/hooks/useShopify"
import { useMediaMobile } from "@app/hooks/useMedia"
import { useRoutes } from "@app/hooks/useRoutes"
import ArticleNavigation from "./ArticleNavigation"
import ArticleFeaturedCard from "./ArticleFeaturedCard"
import ArticleCard from "./ArticleCard"

const ArticleList: React.FC<PageProps<Props>> = ({ page, articles }) => {
  const { edgeNormaliser } = useShopify()

  const isMobile = useMediaMobile()

  const { urlResolver } = useRoutes()

  const items = edgeNormaliser(articles)

  const featured = items?.find(({ handle }) => handle?.current === page?.featured?.handle?.current)

  const categories =
    page?.categories?.map(category => ({
      ...category,
      link: urlResolver(category),
    })) || []

  return (
    <Box as="section" py={8}>
      <Container maxW="container.xl" px={[4, 16]}>
        <Heading as="h1" textAlign="left" mb={5} size="display">
          {page?.title}
        </Heading>
        <ArticleNavigation />
      </Container>
      {featured && <ArticleFeaturedCard article={featured} />}
      <Container maxW="container.xl" px={[4, 16]}>
        {categories?.map((category, index) => {
          const columns = index % 2 === 1 ? 3 : 2
          const filteredArticles = items
            ?.filter(article => article?.categories?.some(item => item?.handle?.current === category?.handle.current))
            ?.slice(0, isMobile ? 2 : columns)
          return filteredArticles?.length > 0 ? (
            <div key={category?.title?.toString()}>
              <Heading size="h2" as="h3" mb={2}>
                {category?.title}
              </Heading>
              <Text size="16" mb={6}>
                {category?.description}
              </Text>
              <SimpleGrid columns={[1, columns]} spacing={5} mb={8}>
                {filteredArticles.map(article => (
                  <ArticleCard key={article.handle.current} article={article} />
                ))}
              </SimpleGrid>
              <Flex justify="center" align="center">
                <Button variant="outline" as={Link} to={category.link.url} w={["full", "auto"]}>
                  EXPLORE ALL ENTRIES
                </Button>
              </Flex>
              {index !== (page?.categories?.length ?? 0) - 1 && <Divider />}
            </div>
          ) : null
        })}
      </Container>
    </Box>
  )
}

export default React.memo(ArticleList)
